import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { URL } from 'src/app/helpers';
import { env, env_dev, env_local } from 'src/app/configs/config';

@Injectable({ providedIn: 'root' })
export class EnvService {
  private config: any;

  constructor(private loc: Location) {
  }

  async load(): Promise<any> {
    let environment = {};

    // const angularRoute = this.loc.path();
    // const url = window.location.href;
    // const domainAndApp = url.replace(angularRoute, '');
    // let hostUrl = domainAndApp;

    // const checkSlash = domainAndApp.substring(domainAndApp.length, domainAndApp.length-1);
    // if (checkSlash == '/') {
    //   hostUrl = domainAndApp.substring(0, domainAndApp.length-1)
    // }

    // if (hostUrl == URL.LOCAL) {
    //   environment = env_local;
    // } else if (hostUrl == URL.DEV) {
    //   environment = env_dev;
    // } else if (hostUrl == URL.PROD) {
    //   environment = env;
    // } else {
    //   environment = env;
    // }

    environment = env_dev; // Fix prod
    // environment = env_local;

    this.config = environment || env;
    return this.config;
  }

  getConfig(): any {
    return this.config || env;
  }
}
